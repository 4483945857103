import * as React from 'react';
import { useState, useEffect, memo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { GetBlogData, DeletePost } from "../../Services/Api";
import { Link} from 'react-router-dom';
import { Box, Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';

const Delete = async (id) => {
  
    const data = new FormData();
    data.append("route", id)
    const response =  await axios.post("https://svvclinic.com/DeleteBlog.php",data)
    if (response.status == 200) {
        window.location.reload();
    }
}


const columns = [
    { field: 'Route', headerName: 'Route', width: 200 },
    { field: 'Thumbnail', headerName: 'Thumbnail', width: 200 ,  renderCell: params => <img src={`https://svvclinic.com/uploads/${params.row.Thumbnail}`} style={{ width: 60,   borderRadius: "50%" }} /> },
    { field: 'Tittle', headerName: 'Title', width: 200 },

    {
        field: 'Description',
        headerName: 'Description',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 200,
    },

    {
        field: 'Edit', headerName: 'Edit', width: 200, renderCell: params => <Link to={`/Blog-edit/${params.row.Route}`}><button style={{ width: 100 }}>Edit</button> </Link>
    },

    {
        field: 'Delete', headerName: 'Delete', width: 200, renderCell: params => <button style={{ width: 100 }} onClick={(e) => Delete(params.row.Route)}>Delete</button>
    }
];

const TodayBlogData = () => {
    const [TotalBlogs, setToalBlogs] = useState([]);
   
    useEffect(() => {
        const AppointmentData = async () => {
            const TotalBlog =  await axios.get("https://svvclinic.com/GetPost.php")
            setToalBlogs(TotalBlog.data);
            var i = TotalBlogs.length;
           
            
        }
        AppointmentData()
    }, [])
    TotalBlogs.reverse()
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

     
             
    return (
        <>
            <div className="Table-container">
                <Box>
                    <DataGrid
                        rows={TotalBlogs}
                        columns={columns}
                        getRowId={(TotalBlogs) => TotalBlogs.Route}
                        style={{minHeight:400}}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10]} />
                </Box>



            </div>
        </>
    );
}
export default memo(TodayBlogData);