import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
export const OurServices = () => {
    return (
        <Box>
            <Box style={{ textAlign: "center" , marginTop:30 }}>
                <Typography variant="h2" className="Our-services" style={{ color: "#18AD96" , fontWeight:700 , fontSize:"2.5rem" , lineHeight:"55px" , textShadow:"none" , marginBottom:13 , fontWeight:800 ,  letterSpacing:2}}>
                    Our Services
                </Typography>
                <h4 style={{color:"#666666" , fontWeight:600}} >Get back to living your best life – Award-winning surgeon, Dr.  Vireesha, can help you overcome Anorectal issues.</h4>
            </Box>
            <Box className="Services-Box">
                <Box className="kidney">
                    <img src="Piles.webp" alt="piles-logo" style={{width:120}} />
                    <span className="kidney-span">Piles</span>
                    <Typography>
                    Piles, also known as hemorrhoids, are swollen blood vessels located in the rectum and anus.
                    </Typography>
                    <Box style={{  width:"100%", position:"absolute" , bottom:30 , marginTop:10}}>
                    <Link to="treatment/piles"> <Button variant="contained" style={{backgroundColor:"#18AD96" , textAlign:"center"}}> Read More</Button></Link>
                    </Box>
                </Box>

                <Box className="kidney">
                    <img src="Fissure.webp" alt="fissure-logo" style={{width:120}} />
                    <span className="kidney-span">Fissure</span>
                    <Typography>
                    An anal fissure is a small tear or split in the mucous membrane lining of the anus or lower rectum.
                    </Typography>
                     <Box style={{  width:"100%", position:"absolute" , bottom:30 , marginTop:10}}>
                    <Link to="/treatment/fissure"> <Button variant="contained" style={{backgroundColor:"#18AD96"}}> Read More</Button></Link>
                    </Box>
                </Box>

                <Box className="kidney">
                    <img src="obsteric.webp" alt="obsteric-logo" style={{width:120}}/>
                    <span className="kidney-span">Obstetric Fistula</span>
                    <Typography>
                    Obstetric fistula is a severe childbirth injury that occurs during prolonged or obstructed labor.
                    </Typography>
                     <Box style={{  width:"100%", position:"absolute" , bottom:30 , marginTop:10}}>
                    <Link to="treatment/obsteric"> <Button variant="contained" style={{backgroundColor:"#18AD96"}}> Read More</Button></Link>
                    </Box>
                </Box>

                <Box className="kidney">
                    <img src="Anal.webp" alt="Anal-logo" style={{width:120}}/>
                    <span className="kidney-span">Anal Fistula</span>
                    <Typography>
                    An anal fistula is an abnormal tunnel-like passage that develops between the inner lining of the anal canal or rectum and the skin around the anus.
                    </Typography>
                     <Box style={{  width:"100%", position:"absolute" , bottom:30 , marginTop:10}}>
                    <Link to="treatment/fistula"> <Button variant="contained" style={{backgroundColor:"#18AD96"}}> Read More</Button></Link>
                    </Box>
                </Box>

                <Box className="kidney">
                    <img src="constipation.webp" alt="Kidney-logo" style={{width:120}}/>
                    <span className="kidney-span">Constipation</span>
                    <Typography>
                    Constipation refers to irregular bowel movements or difficulty in passing stools.
                    </Typography>
                     <Box style={{  width:"100%", position:"absolute" , bottom:30 , marginTop:10}}>
                    <Link to="treatment/constipation"> <Button variant="contained" style={{backgroundColor:"#18AD96"}}> Read More</Button></Link>
                    </Box>
                </Box>

                <Box className="kidney">
                    <img src="kshar.webp" alt="kshar sutra" style={{width:120}}/>
                    <span className="kidney-span">Kshar sutra karma Procedure</span>
                    <Typography>
                    Anorectal cancers include cancer in the esophagus, stomach, liver, pancreas, colon, and rectum.
                    </Typography>
                     <Box style={{  width:"100%", position:"absolute" , bottom:30 , marginTop:10}}>
                    <Link to="/treatment/Kshar-Sutra"> <Button variant="contained" style={{backgroundColor:"#18AD96"}}> Read More</Button></Link>
                    </Box>
                </Box>
            </Box>
            
            <Box style={{ background: "#18AD96", textAlign: "center", color: "#fff" }}>
                <Box className="Just-Book">
                    <h2 style={{fontSize:30}}>Just Book An Appointment & You are Done!</h2>
                    <Link to="/Book-Appointment" style={{ textDecoration: "none", color: "#fff" }} > <Button className="Book-appointment-btn" style={{padding:"14px 30px 16px !important"}}>Request An  Appointment</Button></Link>
                </Box>
            </Box>
        </Box>
    )
}