import { Box, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const Fillsvg = () => {
    return(
<svg 
                width="28" 
                height="28" 
                viewBox="0 0 24 24" 
                fill="yellow" 
                stroke="#393939" 
                strokeWidth="1" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
            >
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
            </svg>
    )
}

export const EmptySvg = () => {
    return(
    <svg 
                width="28" 
                height="28" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="#393939" 
                strokeWidth="1" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
            >
                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
            </svg>
    )
}

export const Carousels = () => {
    return(
        <Box>
            <Box style={{width : "100%"}}>
        <Carousel
            responsive={responsive}
            swipeable={false}
            draggable={false}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            keyBoardControl={true}>

           <Box style={{padding : 20 , textAlign : "center"}}>
            <img src="https://drmustafarazvi.com/wp-content/uploads/2021/10/user-dummy-pic-250x252.png" alt="Patient-logo" />
            <h5>Zareen sultan</h5>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Typography>The good physician treats the disease; the great physician treats the patient who has disease."I write this to thank Dr. Vireesha.She’s a very patient person who genuinely cares about her patients. She always takes time to explain things to me in terms that I can understand. She’s diligent in making sure my health is the best it can be. The entire staff is wonderful. I would recommend  to anyone seeking a kind, compassionate, and brilliant doctor!</Typography>
            </Box>

           <Box style={{padding : 20 , textAlign : "center"}}>
            
            <img src="https://drmustafarazvi.com/wp-content/uploads/2021/10/user-dummy-pic-250x252.png" alt="Patient-logo" />
            <h5>Yashwant Moningi</h5>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Typography>Good doctor. She is very simple and gentle in her behavior. She will give sufficient time to each patient. She studies the patient and disease meticulously, and also I visited multiple clinics and hospitals this is the clinics which is valid for money, Overall each and every member of the clinic responds well and guide us well.</Typography>
           </Box>
           
           <Box style={{padding : 20 , textAlign : "center"}}>
           
            <img src="https://drmustafarazvi.com/wp-content/uploads/2021/10/user-dummy-pic-250x252.png" alt="Patient-logo" />
            <h5>Shaik Jain begum</h5>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Typography>Doctor is very friendly in nature and it's a very good hospital to take service.i will recommend every one to visit this hospital 👍👍👍</Typography>
           </Box>

           <Box style={{padding : 20 , textAlign : "center"}}>
           
            <img src="https://drmustafarazvi.com/wp-content/uploads/2021/10/user-dummy-pic-250x252.png" alt="Patient-logo" />
            <h5>Srinu</h5>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Typography>Doctor  is simply splendid. She makes the patient feel very comfortable and is very friendly. She is  diagnosed my long term problem in a single go. And my son 2y motion problem with in days totally clear Madam is very precise is diagnosis and treatment. I strongly recommend thanks to you madam</Typography>
           </Box>

           <Box style={{padding : 20 , textAlign : "center"}}>
           
            <img src="https://drmustafarazvi.com/wp-content/uploads/2021/10/user-dummy-pic-250x252.png" alt="Patient-logo" />
            <h5>Harshi menni</h5>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            <Fillsvg/>
            


            <Typography>Dr. Vireesha is very friendly and helpful. I especially loved how Dr. Vireesha really took her time to explain my mother conditions with me as well as my treatment options. I had a great visit, so I highly recommend this clinic.</Typography>
           </Box>

        </Carousel>
        </Box>
        </Box>
    )
}