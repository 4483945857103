import { Box, Grid, Typography, Button } from "@mui/material"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import { useEffect, useState } from "react";
import { Book_Appointement } from "../../Services/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertDialog from "../Book-Appointment/Dialoge";
import moment from "moment";
const Data = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    msg: "",
    Date: moment().format('MMMM Do YYYY'),
    Time: moment().format('h:mm:ss a')
}

var regexname = /^[a-zA-Z]+[a-zA-Z\s]*?[^0-9]$/;
var emailregex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
var msgregex = /^[a-zA-Z0-9@.]+[a-zA-Z0-9\s]*?$/;

export const Contact = () => {

    const [UserData, setData] = useState(Data);
    const [open, setOpen] = useState(false);
 
    const Book = (event) => {
        setData({ ...UserData, [event.target.name]: event.target.value });
    }

    const Submit = async () => {
        if (!UserData.name || !regexname.test(UserData.name)) {
            toast.error("Please Enter Name")
        }
        else if (!UserData.email || !emailregex.test(UserData.email)) {
            toast.error("Please Enter Email Id")
        }
        else if (!UserData.phone || UserData.phone.length!=10 || UserData.phone<1) {
            toast.error("Please Enter Mobile Number")
        } else if (UserData.phone.length != 10) {
            toast.error("Please Enter Correct Mobile Number")
        }
        else if (!UserData.subject || !msgregex.test(!UserData.subject)) {
            toast.error("Enter Your Subject")
        }
        else{
        const Response = await Book_Appointement(UserData) ; 
        if(Response.status == 200){
            toast.success("Your Appointement Successfully Booked") ;
            setOpen(true);
            }
            else{
                toast.error("Please Check Your Internet Connection") ;
            }
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (

        <Box>
            <Box className="About-Bg blog-bg" style={{ marginTop: 0 }}>
                <h1 >Contact Us</h1>
            </Box>
            <Box className="Contact-container" style={{ display: "flex", marginTop: 30}}>
                
                  
                        <Box className="Contact-Box"  >
                            <PhoneIcon style={{ fontSize: 60, color: "#18AD96", marginBottom: 20 }} />
                            <Typography variant="h5" >CONTACT DETAILS</Typography>
                            <Box style={{ marginTop: 20, textAlign: "left" }}>
                                <span>Please call or email contact form and we will be happy to assist you.</span><br/>
                                <Typography variant="h6"> <a href="tel:9518312766" >+919518312766</a></Typography>
                            </Box>
                        </Box>
                    

                     
                        <Box className="Contact-Box" >
                            <LocationOnIcon style={{ fontSize: 60, color: "#18AD96", marginBottom: 20 }} />
                            <Typography variant="h5">LOCATION</Typography>
                            <Box style={{ marginTop: 20, textAlign: "left" }}>
                                <Typography variant="span">Ground floor, H.No: 2, 56/9, Meridian School Rd, beside Mallikarjuna Super Market, opp. Vegetable Market, Ayyappa Society, Hyderabad, Telangana 500081. Phone Number: 9518312766</Typography >
                            </Box>
                        </Box>
                     
            </Box>

            <Box className="BookAppointment-Box" style={{ margin: "40px auto auto" }}>
                <h2>Book An Appointment</h2>
                <p>Please call or email contact form and we will be happy to assist you.</p>
                <Box className="Book-appointment-input">
                <Grid container spacing={5}>
                    <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                        <Box>
                            <input type="text" name='name' placeholder="Your Name : " className="contact-input" onChange={Book} ></input>
                        </Box>
                    </Grid>

                    <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                        <Box>
                            <input type="text" name='email' placeholder="Your Email :" className="contact-input" onChange={Book}></input>
                        </Box>
                    </Grid>

                    <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                        <Box>
                            <input type="number" name='phone' placeholder="Your Phone : " className="contact-input" onChange={Book}></input>
                        </Box>
                    </Grid>

                    <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                        <Box>
                            <input type="text" name='subject' placeholder="Subject :" className="contact-input" onChange={Book}></input>
                        </Box>
                    </Grid>

                    <Grid item md={12} lg={12} xl={12} sm={12} xs={12}>
                        <Box>
                            <textarea type="text" name='msg' placeholder="Message (optional)"  onChange={Book} rows={5} cols={50} ></textarea>
                        </Box>
                    </Grid>

                </Grid>

                <Button className="Book-appointment-btn" onClick={Submit} style={{ marginTop: 20 }}>Book Appointment</Button>
            </Box>
</Box>
            <Box style={{ textAlign: "center" }} >
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1948734.7485134404!2d75.95004305625!3d17.4538473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91f2f4e6be4d%3A0x4f458c022c5c3973!2sSVV%20Clinic%20-%20Best%20Ayurvedic%20Piles%2C%20Fistula%2C%20Fissures%2C%20Haemorrhoid%20Treatment%20Clinic!5e0!3m2!1sen!2sin!4v1687089003718!5m2!1sen!2sin" width={"80%"} height={"400px"} style={{ margin: "auto", margin: "30px auto" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Box>
            <Box>
            <AlertDialog open = {open} setOpen={setOpen}/>
            </Box>
            <ToastContainer/>
            
        </Box>
    )
}