import * as React from 'react';
import { useState, useEffect } from 'react';
import moment from "moment"
import { DataGrid } from '@mui/x-data-grid';
import { TodayAppointments } from "../../Services/Api";
import { Box , Backdrop , CircularProgress } from '@mui/material';

const columns = [
    { field: '_id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'name', width: 130 },
    { field: 'email', headerName: 'email', width: 130 },
    {
        field: 'phone',
        headerName: 'Phone Number',
        type: 'number',
        width: 130,
    },
    {
        field: 'subject',
        headerName: 'Subject',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
    },
    {
        field: 'msg',
        headerName: 'Description',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,

    }
    ,
  {
    field: 'Date',
    headerName: 'Date',
    
    sortable: true,
    width: 160,
   
  }

  ,
  {
    field: 'Time',
    headerName: 'Time',
    sortable: true,
    width: 160,
   
  }
];

export default function TodayAppointmentData() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const [todayAppointement, setTodayAppointement] = useState([]);
    const Date = {
        Date: moment().format('MMMM Do YYYY')
    }

    useEffect(() => {
        const AppointmentData = async () => {
            const TodayAppointment = await TodayAppointments(Date);
            setTodayAppointement(TodayAppointment.data.Data);
            if(TodayAppointment.data.Data.length < 0  == 200){
alert("No Data")
            }
        }
        AppointmentData()
    }, [])
    todayAppointement.reverse()
    return (
        <div className= "Table-container">
            
                 

<DataGrid
                rows={todayAppointement}
                columns={columns}
                getRowId={(todayAppointement) => todayAppointement._id}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
            pageSizeOptions={[5, 10]}
            checkboxSelection />

                 
                
            
           
        </div>
    );
}