import { Box, Typography } from "@mui/material"
import "../../style.css"
import { useEffect } from "react"
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export const Main = () => {
    useEffect(() => {
        window.scrollTo(0, 0)

        const ctx = gsap.context(() => {
            let t1 = gsap.timeline();
            t1.from(".About-Bg h1",{
                x:"-100%"
            })
        })
        return () => ctx.revert()

    }, [])
    return (
        <Box>
            <Box>
                <Box className="About-Bg blog-bg" >
                    <h1 >About Doctor</h1>
                </Box>
                <Box className="About-page">
                    <Box className="About-main" >
                        <Box className="About-page-left-side" >
                            <Box className="About-hero">
                                <Box>
                                    <img src="Mobile Frame.webp" />
                                    <Box className="About-left-text">
                                        <h2>Dr. Vireesha </h2>
<hr/>
                                        <p>
                                            Anorectal problems<br />
                                            Kshar sutra karma procedures
                                        </p>
                                        <h2>Contact Info </h2>
                                        <ul style={{padding:"0px"}}>

                                       
                                        <li style={{display:"flex" , alignItems:"center" , gap:10 }}>
                                        <PhoneIcon style={{ color: "#18AD96" }} /><a href="tel:919518312766">919518312766</a>
                                        </li>
                                        <li style={{display:"flex" , alignItems:"center" , gap:10 , marginTop:10}}>
                                         <EmailIcon style={{color:"#18AD96"}}/>   <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=svvclinic@gmail.com" target="_blank">svvclinic@gmail.com</a>
                                            </li>
                                            </ul>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="About-Paragraph">
                            <h4>About Dr Vireesha</h4>
                            <Typography>
                                Dr. Vireesha Bogireddy - An Expert in Anorectal Care
                                Welcome to the SVV Clinic website, Dr. Vireesha Bogireddy, a renowned specialist in anorectal problems and a master of Kshar Sutra karma procedures. With extensive training and experience, Dr. Vireesha Bogireddy is dedicated to providing the highest standard of care for patients dealing with anorectal conditions. Fluent in English, Hindi, Marathi, Kannada, and Telugu. Dr. Vireesha Bogireddy ensures effective communication with patients from diverse backgrounds.
                                <br />
                                <br />
                                Education and Training
                                Dr. Vireesha Bogireddy completed her Bachelor of Ayurvedic Medicine and Surgery (BAMS) from Acharya Deshbhushan Ayurvedic Medical College and Hospital in 2018. This prestigious institution operates under the Rajiv Gandhi University of Health Sciences in Bangalore. Dr. Vireesha Bogireddy had the privilege of training under the guidance of the highly acclaimed and experienced Dr. Nagireddy Bogireddy, a renowned expert in anorectal cases and a practitioner of Kshar Sutra karma procedures for over 30 years.
                                <br />
                                <br />
                                Expertise and Achievements
                                As a specialist in anorectal care, Dr. Vireesha Bogireddy possesses a deep understanding of the various conditions affecting the anal and rectal regions. Her expertise extends to the diagnosis, management, and treatment of all types of anorectal cases. With her mastery of Kshar Sutra karma procedures, Dr. Vireesha Bogireddy offers patients a highly effective and minimally invasive treatment option.
                                Throughout her career, Dr. Vireesha Bogireddy has successfully treated over 10,000 anorectal cases, demonstrating her commitment to delivering optimal patient outcomes. Her exceptional skills and vast experience have enabled her to perform numerous procedures with precision and care.
                                <br />
                                <br />
                                Patient-Centric Approach
                                Dr. Vireesha Bogireddy believes in a patient-centric approach, placing the well-being and comfort of her patients at the forefront of her practice. She ensures that each patient receives personalized attention and comprehensive care tailored to their specific needs. Dr. Vireesha Bogireddy emphasizes open and empathetic communication, empowering her patients to actively participate in their treatment journey.


                                <br />
                                <br />

                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}