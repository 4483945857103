import { Box, Backdrop, CircularProgress, Typography } from "@mui/material"
import { GetBlogId } from "../Services/Api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import axios from "axios";
export const BlogPostPage = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
       
            window.scrollTo(0, 0)
        const Data = async () => {
            const data = new FormData()
            data.append("route",id)
            const response = await axios.post("https://svvclinic.com/FindBlog.php",data);
            setData(response.data);
        }
        Data()
    }, [])

    return (
        <Box style={{ minHeight: "20vh" , color:"#000 !important" }}>
            {
                data.length > 0 ? <Box>
                    <div className="MainImage" style={{backgroundImage:`url(https://svvclinic.com/uploads/${data[0].Thumbnail})`}}>
                    <div >
                        <h1>{data[0].Tittle}</h1>
                        
                    </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: data[0].Content }} style={{ padding: 10, textAlign: "left" , minHeight:"100vh" , color:"#000"}} className="Blog-Image">
                    </div>
                </Box> : ""
            }
        </Box>
    )
}