import { Box, Button, Grid, Typography, CircularProgress, Backdrop } from "@mui/material"
import { Link } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import { GetAppointment, TodayAppointments, GetBlogData } from "../../Services/Api";
import axios from "axios";

const Date = {
    Date: moment().format('MMMM Do YYYY')
}

export const AdminDashboard = () => {
    const [Data, setData] = useState([]);
    const [todayAppointement, setTodayAppointement] = useState([]);
    const [TotalBlogs, setToalBlogs] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0)
        const AppointmentData = async () => {
            const TotalAppointment = await GetAppointment();
            const TodayAppointment = await TodayAppointments(Date);
            const TotalBlog = await axios.get("https://svvclinic.com/GetPost.php")
            setToalBlogs(TotalBlog.data);
            setData(TotalAppointment.data.Data);
            setTodayAppointement(TodayAppointment.data.Data);
        }
        AppointmentData()
    }, [])

    return (
        <Box>
            <Box style={{ height: "70vh" }} >
                <Grid container>

                    <>
                        <Grid item xl={4} lg={3} md={4} sm={6} xs={12}>
                            <Box className="AdminDashboard-box-container" style={{ marginTop: 40 }}>
                                <Box className="AdminDashboard-box-wrapper">
                                    <Typography variant="h5">Total <br /> Appointements </Typography>


                                    <Box style={{ marginTop: 10 }}>
                                        {Data.length}
                                    </Box>
                                    <Link to="/Total-appointements" > <Button variant="contained" >Show All</Button> </Link>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xl={4} lg={3} md={4} sm={6} xs={12}>
                            <Box className="AdminDashboard-box-container" style={{ marginTop: 40 }}>
                                <Box className="AdminDashboard-box-wrapper">
                                    <Typography variant="h5">Today <br /> Appointements </Typography>

                                    <Box style={{ marginTop: 10 }}>
                                        {todayAppointement.length}
                                    </Box>
                                    <Link to="/Today-appointements" > <Button variant="contained" >Show All</Button> </Link>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xl={4} lg={3} md={4} sm={6} xs={12}>
                            <Box className="AdminDashboard-box-container" style={{ marginTop: 40 }}>
                                <Box className="AdminDashboard-box-wrapper">
                                    <Typography variant="h5">Total <br /> Blogs </Typography>


                                    <Box style={{ marginTop: 10 }}>
                                        {TotalBlogs.length}
                                    </Box>
                                    <Link to="/Total-blogs" > <Button variant="contained" style={{ width: "max-content" }}>Show All</Button> </Link>
                                </Box>
                            </Box>
                        </Grid>
                    </>
                </Grid>
            </Box>
        </Box>
    )
}