import { Box, Button , Typography} from "@mui/material"
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmailIcon from '@mui/icons-material/Email';
import "../style.css";
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect, useState } from "react";
import { TemporaryDrawer, AdminDrawer } from "./Home/Top-Menu";
import { Link } from "react-router-dom";

import PositionedMenu, { AdminMenu } from "./Menu";

export const Header = () => {
    const [openDrawer, setDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorE2, setAnchorE2] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
       
    };

    const toggleDrawer = () => {
        setDrawer(true);
    }
    const handleClickAdmin = (event) => {
        setAnchorE2(event.currentTarget);
    };

    useEffect(() => {
        if (localStorage.getItem("login")) {
            document.getElementById("Admin").style.display = "flex";
        }
    }, [])


    
    return (
        <Box className="Header-container">
            <Box className="Header-Wrapper">
                <Box className="left-side-logo" style={{ width: "20%", marginLeft: "40px" }}>
                   <a href="/"> <img src="/svvicon (1).ico" alt="Website-logo" style={{ width: "50%" }} /></a>
                </Box>
                <Box class="right-side" style={{ width: "80%" }}>

                    <ul >
                        <li>
                            <div>
                                <PhoneIcon style={{ color: "#18AD96" }} />
                            </div>
                            <Typography>Appointment
                                <br />
                                <strong ><a href="tel:+919518312766" style={{ color: "#18AD96" }}>+919518312766</a></strong>
                            </Typography>
                        </li>

                        <li>
                            <div>
                                <AccessTimeIcon style={{ color: "#18AD96", alignItems: "baseline" }} />
                            </div>
                            <Typography>Mon  -  To - Sat : <strong >10:30 AM TO 2:00 PM <br />& 5:30 PM TO 9:00 PM</strong>

                            </Typography>
                        </li>


                        <li>
                            <div>
                                <EmailIcon style={{ color: "#18AD96" }} />
                            </div>
                            <Typography>Email Us
                                <br />
                                <strong>
                                    <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=svvclinic@gmail.com" target="_blank" style={{ color: "#18AD96" }}>svvclinic@gmail.com</a>
                                    </strong>
                            </Typography>
                        </li>
                    </ul>

                </Box>
                <Box className="MenuIcon">
                    <MenuIcon className="MenuIcon" onClick={toggleDrawer} />
                </Box>
            </Box>

            <Box className="navbar">
                <ul>
                    <li>
                        <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
                        <Typography>   Home </Typography>
                        </Link>
                    </li>
                    <li>
                        <Link to="/About" style={{ textDecoration: "none", color: "#fff" }} >
                        <Typography>      About </Typography>
                        </Link>
                    </li>
                    <li id="menu" onClick={handleClick} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}   >
                    <Typography> Treatment </Typography>
                        <ArrowDropDownIcon />
                    </li>
                    <li>
                    <Link to="/doctor" style={{ textDecoration: "none", color: "#fff" }} >
                       
                        <Typography>  Doctor </Typography>
                         
                    </Link>
                    </li>
                    <li>
                        <Link to="/Blog" style={{ textDecoration: "none", color: "#fff" }} >
                            <Typography>Blog</Typography>
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact" style={{ textDecoration: "none", color: "#fff" }} >
                        <Typography>  Contact </Typography>
                        </Link>
                    </li>
                    <li id="Admin" onClick={handleClickAdmin} style={{ display: "none", alignItems: "center", cursor: "pointer", }}   >
                    <Typography> Admin </Typography>
                        <ArrowDropDownIcon />
                    </li>
                    <li>
                        <Link to="/Book-Appointment" style={{ textDecoration: "none", color: "#fff" }} >
                            <Button className="Book-appointment-btn">Book Appointment</Button>
                        </Link>
                    </li>
                </ul>
                <Box>
                <PositionedMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                <AdminMenu anchorE2={anchorE2} setAnchorE2={setAnchorE2} /></Box>
                <Box class="mobile-heading">
                    <h2><a href="/Book-appointment" style={{color:"#ffffff"}}>Book Appointment</a></h2>
                </Box>
            </Box>
            <TemporaryDrawer openDrawer={openDrawer} setDrawer={setDrawer} />
        </Box>
    )
}