import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { GetAppointment } from "../../Services/Api";
import { Box, Backdrop, CircularProgress } from '@mui/material';

const columns = [
  { field: '_id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'name', width: 130 },
  { field: 'email', headerName: 'email', width: 180 },
  {
    field: 'phone',
    headerName: 'Phone Number',
    type: 'number',
    width: 130,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
  },
  {
    field: 'msg',
    headerName: 'Description',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,

  }
  ,
  {
    field: 'Date',
    headerName: 'Date',
    sortable: true,
    width: 160,

  }
  ,
  {
    field: 'Time',
    headerName: 'Time',

    sortable: true,
    width: 160,

  }
];
export default function DataTable() {

  const [Data, setData] = React.useState([]);
  React.useEffect(() => {
    const AppointmentData = async () => {
      const response = await GetAppointment();
      setData(response.data.Data);
    }
    AppointmentData()
  }, [])
  Data.reverse()
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Table-container">


      <Box>
        <DataGrid
          rows={Data}
          columns={columns}
          getRowId={(Data) => Data._id}
          
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30]}
          checkboxSelection
        />
      </Box>


    </div>
  );
}