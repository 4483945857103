import * as React from 'react';
import { Box, Button, Drawer, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PositionedMenu from '../Menu';
import ArrowDropDownIcon  from '@mui/icons-material/ArrowDropDown';
import  ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
export function TemporaryDrawer({ openDrawer, setDrawer }) {
  const [Show, setShow] = React.useState(false);
    const [Show1, setShow1] = React.useState(false);
  const show = () => {
    setShow(true);
    document.getElementById("Treatment").style.display = "block";

}

const handleshow = () => {
    setShow(false);
    document.getElementById("Treatment").style.display = "none";
}
  const [anchorEl, setAnchorEl] = React.useState(null);

  const Treatment = () => {
    document.getElementById("Treatment").style.display = "block";
  }

   

  const Admin = () => {

    document.getElementById("Admin").style.display = "flex";
  }

  const handleClose = () => {
    setDrawer(false);
    setShow(false);
  }

  const close = () => {
    document.getElementById("Treatment").style.display = "none";
  }
  return (
    <div className="mobile-container">
      <Drawer
        open={openDrawer}
        onClose={handleClose}
        style={{ zIndex: 4000 }}
        className="Mobile-drawer-wrapper"
      >
        <ul className="mobile-drawer"   >

          <li onClick={handleClose}>
            <Link to="/"  >  Home </Link>
          </li>
          <li onClick={handleClose}>
            <Link to="/About" >  About </Link>

          </li>
          <li >
            <Box style={{display:"flex" , alignItems:"center" , justifyContent:"center"}}>
             <Typography onClick={show}> Treatment</Typography>  
              { Show ? <ArrowDropUpIcon onClick={handleshow}  /> : <ArrowDropDownIcon onClick={show}/> }
              </Box>
            <Box id="Treatment" style={{ display: "none"   }}>
              <ul style={{listStyle:"none" , color : "#fff" , padding:0}}>
                <li onClick={handleClose} style={{padding:0}}>
              <Link to="/treatment/piles">    Piles</Link>
                </li>
                <li onClick={handleClose}>
                <Link to="/treatment/fissure"> Fissure </Link>
                </li>
                <li onClick={handleClose}>
                <Link to="/treatment/obsteric"> Obsteric Fistula </Link>
                </li>
                <li onClick={handleClose}>
                <Link to="/treatment/Fistula"> Anal Fistula </Link>
                </li>
                 
                <li onClick={handleClose}>
                <Link to="/treatment/constipation">Constipation Treatment</Link>
                </li>
                <li onClick={handleClose}>
                <Link to="/treatment/Kshar-Sutra">Kshar Sutra Karma (Procedure)</Link>
                </li>
                

              </ul>
            </Box>

          </li>
          <li onClick={handleClose}>
            <Link to="/Doctor" >  Doctor </Link>

          </li>
          <li onClick={handleClose}>
            <Link to="/Blog" >   Blog </Link>

          </li>
          <li onClick={handleClose}>
            <Link to="/Contact" >  Contact </Link>

          </li>

           

          <li style={{ marginTop:20 , marginBottom:20 }}>
           <Link to="/Book-appointment"> <Button className="Book-appointment-btn" onClick={handleClose}>Book Appointment</Button></Link>

          </li>
        </ul>
      </Drawer>
      <PositionedMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </div >
  );
}